import React from 'react';

const Favicon: React.FC<{longUrl: string, size?: number}> = ({ longUrl, size = 32 }) => {

  const faviconUrl = () => {
      const domainName = new URL(longUrl).hostname;
      return `https://www.google.com/s2/favicons?sz=${size}&domain=${domainName}`;
  }

  return (
      <img src={faviconUrl()}
           width={size}
           height={size}
           style={{
               borderRadius: "50%",
               width: `${size}px`,
               height: `${size}px`,
           }}
      />
  );
};

export default Favicon;
